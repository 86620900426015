import React from "react"
import PropTypes from "prop-types"
import BlocksLayout from "components/layouts/BlocksLayout"

const leftColAttrs = {
  lg: 7,
  md: 6,
}

const rightColAttrs = {
  lg: 5,
  md: 6,
}

const ServiceContentMain = ({ blocks }) => {
  return blocks ? (
    <BlocksLayout
      blocks={blocks}
      leftColAttrs={leftColAttrs}
      rightColAttrs={rightColAttrs}
    />
  ) : null
}

export default ServiceContentMain
