import React from "react"
import PropTypes from "prop-types"
import ServiceContentHeader from "./ServiceContentHeader"
import FoldingFan from "../ui/FoldingFan"
import * as styles from "./ServiceContent.module.scss"
import { Col, Container, Row } from "react-bootstrap"
import WysiwygContent from "../ui/WysiwygContent"
import ServiceContactUs from "./ServiceContactUs"
import UniversalImage from "../ui/UniversalImage"
import CustomCard from "../ui/CustomCard"

// service-content global CSS class is used by Section.module.scss
const ServiceContent = ({ serviceData, leftContent, rightContent, image }) => {
  const leftColAttrs = {
    lg: 7,
    md: 6,
  }

  const rightColAttrs = {
    lg: 5,
    md: 6,
  }

  const {
    title,
    contactLabel,
    contactButtonText,
    contactButtonUrl,
  } = serviceData

  return (
    <CustomCard className="service-content">
      <FoldingFan className={styles.foldingFan} />
      <ServiceContentHeader title={title} />

      <Container>
        <Row>
          <Col {...leftColAttrs}>
            <WysiwygContent
              dangerouslySetInnerHTML={{ __html: leftContent }}
              className={styles.content}
            />
          </Col>
          <Col {...rightColAttrs} className="justify-content-center">
            {image && (
              <div className={styles.serviceImageWrapper}>
                <UniversalImage image={image} className={styles.serviceImage} />
              </div>
            )}
            <WysiwygContent
              dangerouslySetInnerHTML={{ __html: rightContent }}
              className={styles.content}
            />

            <ServiceContactUs
              title={contactLabel}
              buttonText={contactButtonText}
              buttonUri={contactButtonUrl}
            />
          </Col>
        </Row>
      </Container>
    </CustomCard>
  )
}

ServiceContent.propTypes = {
  serviceData: PropTypes.object,
  leftContent: PropTypes.string,
  rightContent: PropTypes.string,
  image: PropTypes.object,
}

export default ServiceContent
