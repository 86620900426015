import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import classNames from "classnames"
import * as styles from "./ServiceMenu.module.scss"
import UniversalLink from "../ui/UniversalLink"

const ServiceMenu = ({ items }) => {
  const location = useLocation()

  return (
    <ul className={styles.list}>
      {items.map(({ name, uri }) => (
        <li
          key={name}
          className={classNames(styles.listItem, {
            [styles.active]: location.pathname === uri,
          })}
        >
          <UniversalLink to={uri} className={styles.link}>
            {name}
          </UniversalLink>
        </li>
      ))}
    </ul>
  )
}

ServiceMenu.propTypes = {
  items: PropTypes.array,
}

export default ServiceMenu
