import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ServiceContentHeader.module.scss"

const ServiceContentHeader = ({ title }) => {
  return <h3 className={styles.title}>{title}</h3>
}

ServiceContentHeader.propTypes = {
  title: PropTypes.string,
}

export default ServiceContentHeader
