import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ServiceContactUs.module.scss"
import { Button } from "react-bootstrap"
import UniversalLink from "../ui/UniversalLink"

const ServiceContactUs = ({ title, buttonText, buttonUri }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <Button as={UniversalLink} to={buttonUri}>
        {buttonText}
      </Button>
    </div>
  )
}

ServiceContactUs.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUri: PropTypes.string,
}

export default ServiceContactUs
