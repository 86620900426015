import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Layout from "components/layouts/Layout"
import SEO from "components/common/Seo"
import PageTitle from "components/common/ui/PageTitle"
import { Col, Container, Row } from "react-bootstrap"
import ServiceMenu from "components/common/Service/ServiceMenu"
import ServiceContent from "components/common/Service/ServiceContent"
import { graphql } from "gatsby"
import * as styles from "./Service.module.scss"
import classNames from "classnames"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { filterByLocale, translate } from "src/utils/language"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import ServiceContentMain from "components/common/Service/ServiceContentMain"

const Service = ({ data, pageContext }) => {
  const { service } = data

  const locale = useCurrentLanguage()

  const services = filterByLocale(data.serviceList.services, locale)
  const serviceSettingsPage = translate(data.serviceSettingsPage, locale)

  const serviceSettings = serviceSettingsPage.serviceSettings

  const [navigationActive, setNavigationActive] = useState(false)
  const navigationRef = useRef()

  const serviceData = {
    title: service.name,
    contactLabel: serviceSettingsPage.serviceSettings.contactLabel,
    contactButtonText: serviceSettingsPage.serviceSettings.contactButtonText,
    contactButtonUrl: serviceSettingsPage.serviceSettings.contactButtonUrl?.uri,
  }

  const customServiceBlocks = service.pageBuilder.blocks

  const title = serviceSettings.servicesListingPage?.title ?? service.name

  const toggleNavbarActive = useCallback(() => {
    if (navigationActive) {
      enableBodyScroll(navigationRef.current)
    } else {
      disableBodyScroll(navigationRef.current)
    }

    setNavigationActive(active => !active)
  }, [navigationActive])

  useEffect(() => {
    return () => clearAllBodyScrollLocks()
  }, [])

  return (
    <Layout background="lightestGrey" page={pageContext?.page}>
      <SEO {...pageContext.seo} />
      <div className={styles.pageTitleWrapper}>
        <PageTitle title={title} />
      </div>
      <Container>
        <button className={styles.breadcrumb} onClick={toggleNavbarActive}>
          {title}
        </button>
      </Container>
      <Container className={styles.container}>
        <Row>
          <Col
            lg={2}
            md={3}
            className={classNames(styles.navigation, {
              [styles.active]: navigationActive,
            })}
            ref={navigationRef}
          >
            {/* <div className={styles.mobilePageTitleWrapper}>
              <PageTitle title={title} />
            </div> */}
            <ServiceMenu items={services} />
          </Col>
          <Col lg={10} md={9} className={styles.secondCol}>
            <ServiceContent
              serviceData={serviceData}
              leftContent={service.serviceAttributes.contentLeft}
              rightContent={service.serviceAttributes.contentRight}
              image={service.serviceAttributes.image}
            />
          </Col>
        </Row>
      </Container>
      <div className={styles.blocks}>
        {customServiceBlocks && (
          <ServiceContentMain blocks={customServiceBlocks} />
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query service($id: String!) {
    service: wpService(id: { eq: $id }) {
      ...ServiceTemplateFragment
    }
    serviceList: allWpService(sort: { fields: menuOrder, order: ASC }) {
      services: nodes {
        name: title
        uri
        language {
          ...LanguageFragment
        }
      }
    }
    serviceSettingsPage: wpSettingsPage(slug: { eq: "service" }) {
      ...ServiceSettingsTemplateFragment
      translations {
        ...ServiceSettingsTemplateFragment
      }
    }
    teamSettingsPage: wpSettingsPage(slug: { eq: "team" }) {
      ...ServiceContactsTemplateFragment
      translations {
        ...ServiceContactsTemplateFragment
      }
    }
    caseStudySettingsPage: wpSettingsPage(slug: { eq: "case-studies" }) {
      ...ServiceCaseStudyTemplateFragment
      translations {
        ...ServiceCaseStudyTemplateFragment
      }
    }
    generalSettingsPage: wpSettingsPage(slug: { eq: "general" }) {
      ...ServiceGeneralSettingsTemplateFragment
      translations {
        ...ServiceGeneralSettingsTemplateFragment
      }
    }
  }
`

Service.propTypes = {
  data: PropTypes.object,
}

export default Service
